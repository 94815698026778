import { Paper, Table, TableRow, TableBody } from "@mui/material";
import Image from "next/image";
import confirmation from "@/assets/images/illustrations/confirmation.png";

import { LoaderImage } from "@/components/Loader/styles";
import * as Styled from "./styles";

interface WidgetProps {
  className?: string;
  noItems?: { content: string };
  title: string;
  headerContent?: React.ReactNode;
  isLoading: boolean;
  tableHeaders: JSX.Element[];
  tableBody: React.ReactNode;
}

const Widget: React.FC<WidgetProps> = ({
  className,
  noItems,
  title,
  headerContent,
  isLoading,
  tableHeaders,
  tableBody,
}) => {
  const wrapper = (children: JSX.Element) => {
    if (noItems) {
      return (
        <Styled.NoItemsCard
          content={noItems.content}
          illustration={
            <Image
              src={confirmation.src}
              alt="No items image"
              width={74}
              height={61}
            />
          }
        />
      );
    }
    return children;
  };
  return (
    <Styled.Wrapper className={className} $noItems={Boolean(noItems)}>
      <Styled.Container component={Paper}>
        <Styled.Header size="small" title={title}>
          {headerContent}
        </Styled.Header>
        {wrapper(
          isLoading ? (
            <LoaderImage
              style={{ margin: "auto", display: "flex", height: "100%" }}
            />
          ) : (
            <Table
              sx={{ minWidth: 100 }}
              size="small"
              aria-label="action items table"
            >
              <Styled.TableHeader>
                <TableRow>{tableHeaders.map((header) => header)}</TableRow>
              </Styled.TableHeader>
              <TableBody>{tableBody}</TableBody>
            </Table>
          )
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Widget;
